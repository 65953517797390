import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";

function PageNotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, [1000]);
  }, []);

  return (
    <div className="d-flex vh-100" style={{ overflow: "hidden" }}>
      <div className="mx-auto my-auto p-4 text-center">
        <SearchOutlined className="text-danger mx-auto h1 d-block mb-3" />
        <div className="font600 text-danger fs-3">RESOURCE NOT FOUND</div>
      </div>
    </div>
  );
}

export default PageNotFound;

import {
  ArrowLeftOutlined,
  CreditCardOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Card, Col, Space } from "antd";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { isEmpty } from "lodash";
import { DataNotFound, SubmitButton } from "../../components/common";
import { tabActions, universalPaymentActions } from "../../config/actions";
import { PrintComponent } from "./PrintComponent";
import VisaPaymentModal from "./VisaPaymentModal";

function PaymentReference() {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const { currentUser, generatedReference, showVisaModal } = useSelector(
    (state) => state.universalPayment
  );
  const [loading, setLoading] = useState(true);
  const [prnData, setPrnData] = useState({});

  useEffect(() => {
    if (!isEmpty(generatedReference)) {
      setPrnData(generatedReference);
    } else {
      setPrnData({});
    }
  }, [generatedReference]);

  const onClickGoBack = () => {
    dispatch(tabActions.setCurrentMenu("payable"));
  };

  const closeVisaModal = () => {
    dispatch(universalPaymentActions.showVisaModal(false));
  };

  const redirectToOnlinePayment = () => {
    setLoading(true);
    dispatch(universalPaymentActions.showVisaModal(true));
  };

  const showPrintButton = useCallback(() => {
    return (
      <SubmitButton
        iconBefore={<PrinterOutlined className="me-1 " />}
        htmlType="button"
        text="PRINT INVOICE"
        disabled={isEmpty(prnData)}
      />
    );
  }, [prnData]);

  return (
    <>
      <Col md={{ span: 16, offset: 4 }}>
        <div className="text-center mb-3">
          <Space>
            <SubmitButton
              iconBefore={<ArrowLeftOutlined />}
              danger
              htmlType="button"
              onClick={onClickGoBack}
              text="BACK"
            />

            <ReactToPrint
              trigger={showPrintButton}
              content={() => componentRef.current}
            />
            {prnData?.reference?.ura_prn && (
              <SubmitButton
                iconBefore={<CreditCardOutlined />}
                type="default"
                htmlType="button"
                onClick={redirectToOnlinePayment}
                text="PAY ONLINE WITH VISA"
              />
            )}
          </Space>
        </div>

        <Card bordered>
          {isEmpty(prnData) ? (
            <DataNotFound message="Generated PRN details Not Found" />
          ) : (
            <div ref={componentRef} className="py-4 px-5">
              <PrintComponent
                currentUser={currentUser}
                generatedReference={prnData}
              />
            </div>
          )}
        </Card>
      </Col>
      {showVisaModal && (
        <VisaPaymentModal
          showVisaModal={showVisaModal}
          closeVisaModal={closeVisaModal}
          generatedReference={prnData}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
}

export default PaymentReference;

import appInitialState from "./app";
import universalPayment from "./universalPayment";
import tab from "./tab";

const initialStates = {
  ...appInitialState,
  ...universalPayment,
  tab,
};

export default initialStates;

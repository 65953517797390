import { Card, Col } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tabActions } from "../../config/actions";
import usePrevious from "../Hooks/usePrevious";
import SearchInvoice from "./SearchInvoice";
import UserDetailForm from "./UserDetailForm";

function Home() {
  const dispatch = useDispatch();
  const { currentHomeTab } = useSelector((state) => state.tab);
  const { searchResult } = useSelector((state) => state.universalPayment);
  const { institutionStructure } = useSelector(
    (state) => state.institutionStructure
  );
  const prevState = usePrevious({ searchResult });

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(searchResult) &&
      searchResult !== prevState.searchResult
    ) {
      dispatch(tabActions.setCurrentMenu("show-invoice"));
    }
  }, [searchResult]);

  return (
    <Col md={{ span: 14, offset: 5 }} key={currentHomeTab}>
      <Card bordered>
        <div className="text-sm text-center fw-bold text-uppercase text-muted">
          Use this portal to generate Payment Reference Number (PRN) to make
          Payments to
          <span className="ms-1">
            {institutionStructure?.institution_name ||
              process.env.REACT_APP_NAME}
          </span>
        </div>

        <UserDetailForm />
        <SearchInvoice />
      </Card>
    </Col>
  );
}

export default Home;

import { ConfigProvider, theme } from "antd";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import rootSaga from "./config/saga";
import history from "./config/services/historyService";
import httpService from "./config/services/httpService";
import configureStore from "./config/store";
import reportWebVitals from "./reportWebVitals";
// import * as serviceWorker from './serviceWorker';

const store = configureStore();
store.runSaga(rootSaga);
httpService.setupInterceptors(store, history);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: process.env.REACT_APP_PRIMARY_COLOR || "#10ac84",
            colorWarning: process.env.REACT_APP_WARNING_COLOR || "#f5cd79",
            algorithm: theme.darkAlgorithm,
            // borderRadius: 0,
          },
        }}
        componentSize="small"
      >
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

// serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals();

import { Modal } from "antd";
import PropTypes, { object } from "prop-types";
import React from "react";
import Iframe from "react-iframe";
import { DataSpinner } from "../../components/common";

function VisaPaymentModal({
  closeVisaModal,
  showVisaModal,
  generatedReference,
  loading,
  setLoading,
}) {
  const uraRedirectUrl =
    process.env.REACT_APP_URA_ONLINE_PAYMENT_REDIRECT ||
    "http://testpmtservice.ura.go.ug/payment.do?dispatch=showHistory&searchPrnNo=";

  const url = `${uraRedirectUrl}${generatedReference.reference.ura_prn}`;

  return (
    <Modal
      open={showVisaModal}
      onCancel={closeVisaModal}
      width={1000}
      centered
      keyboard={false}
      maskClosable={false}
      cancelText="CLOSE"
      cancelButtonProps={{
        className: "fw-bold text-uppercase",
        danger: true,
        type: "primary",
      }}
      title={`ONLINE VISA PAYMENT FOR ${
        generatedReference.reference.ura_prn
      } - [${parseInt(generatedReference.amount_due, 10).toLocaleString()} ${
        generatedReference?.currency || "UGX"
      }]`}
      onOk={closeVisaModal}
    >
      {loading ? (
        <DataSpinner text="Please wait..." className="text-primary" />
      ) : null}
      <Iframe
        title="Online Visa Payments"
        onLoad={() => setLoading(false)}
        url={url}
        loading="loading"
        height="100%"
        width="100%"
        frameBorder="100"
        marginHeight="0"
        marginWidth="0"
      />
    </Modal>
  );
}

VisaPaymentModal.propTypes = {
  closeVisaModal: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  showVisaModal: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  generatedReference: PropTypes.oneOfType([object]).isRequired,
};

export default VisaPaymentModal;

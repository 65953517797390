import { DatePicker, Form, Input, InputNumber, Select, TimePicker } from "antd";
import { toUpper } from "lodash";
import PropTypes, { any, array, object } from "prop-types";
import React from "react";

const { TextArea } = Input;

function AntDInputText({
  name,
  label,
  rules,
  itemAttributes,
  inputAttributes,
  type,
  options,
}) {
  return (
    <Form.Item
      key={name}
      label={label}
      name={name}
      rules={rules}
      className="mb-2"
      {...itemAttributes}
    >
      {(type === "select" && (
        <Select
          placeholder="Select option"
          allowClear
          showSearch
          filterOption={(input, option) =>
            (toUpper(option?.label) ?? "").includes(toUpper(input))
          }
          options={options}
          {...inputAttributes}
        />
      )) ||
        (type === "textarea" && <TextArea rows={4} {...inputAttributes} />) ||
        (type === "number" && <InputNumber {...inputAttributes} />) ||
        (type === "date" && (
          <DatePicker
            style={{
              width: "100%",
            }}
            format="DD/MM/YYYY"
            {...inputAttributes}
          />
        )) ||
        (type === "timer" && (
          <TimePicker
            style={{
              width: "100%",
            }}
            {...inputAttributes}
          />
        )) ||
        (type === "password" && <Input.Password {...inputAttributes} />) || (
          <Input {...inputAttributes} />
        )}
    </Form.Item>
  );
}

AntDInputText.defaultProps = {
  rules: [],
  options: [],
  itemAttributes: {},
  inputAttributes: {},
  type: "text",
  label: null,
};

AntDInputText.propTypes = {
  name: PropTypes.oneOfType([any]).isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  rules: PropTypes.oneOfType([array]),
  options: PropTypes.oneOfType([array]),
  itemAttributes: PropTypes.oneOfType([object]),
  inputAttributes: PropTypes.oneOfType([object]),
};

export default AntDInputText;

import { institutionStructureActions } from "../../actions";
import initialState from "../../initialState";

function institutionStructure(
  state = initialState.institutionStructure,
  actions
) {
  switch (actions.type) {
    case institutionStructureActions.GET_INSTITUTION_STRUCTURE_REQUEST:
      return {
        ...state,
        institutionStructureError: {},
        gettingInstitutionStructure: true,
      };
    case institutionStructureActions.GET_INSTITUTION_STRUCTURE_SUCCESS:
      return {
        ...state,
        institutionStructure: actions.data,
        institutionStructureError: {},
        gettingInstitutionStructure: false,
      };
    case institutionStructureActions.GET_INSTITUTION_STRUCTURE_ERROR:
      return {
        ...state,
        institutionStructureError: actions.error,
        gettingInstitutionStructure: false,
      };
    default:
      return state;
  }
}

export default institutionStructure;

const tab = {
  SET_CURRENT_HOME_TAB: "SET_CURRENT_HOME_TAB",

  SET_CURRENT_MENU: "SET_CURRENT_MENU",

  setCurrentHomeTab: (activeTab) => ({
    type: tab.SET_CURRENT_HOME_TAB,
    activeTab,
  }),

  setCurrentMenu: (currentMenu) => ({
    type: tab.SET_CURRENT_MENU,
    currentMenu,
  }),
};

export default tab;

import { isEmpty, orderBy, toUpper } from "lodash";

export const formatDepartments = (departments) => {
  const departmentOptions = [];
  departments.forEach((department) => {
    departmentOptions.push({
      label: department.department_title,
      value: department.id,
    });
  });
  return departmentOptions;
};

export const formatFaculties = (faculties) => {
  const facultiesList = [];
  faculties.forEach((faculty) => {
    facultiesList.push({
      label: `${faculty.faculty_title}`,
      value: faculty.id,
    });
  });
  return facultiesList;
};

export const formatEntryYears = (programme = []) => {
  const entryYearList = [];
  if (!isEmpty(programme.entry_years))
    programme.entry_years.forEach((entryYear) => {
      entryYearList.push({
        label: `${entryYear.entry_year}`,
        value: entryYear.id,
      });
    });
  return entryYearList;
};

export const formatUnebSubjects = (subjects, studyLevel = null) => {
  const subjectOptions = [];
  subjects.forEach((subject) => {
    if (studyLevel) {
      if (
        toUpper(subject.unebStudyLevel.metadata_value) === toUpper(studyLevel)
      ) {
        subjectOptions.push({
          label: `${subject.uneb_subject_title} (${subject.uneb_subject_code})`,
          value: subject.id,
        });
      }
    } else {
      subjectOptions.push({
        label: `${subject.uneb_subject_title} (${subject.uneb_subject_code})`,
        value: subject.id,
      });
    }
  });
  return subjectOptions;
};

export const formatMetadata = (metadata, fieldName) => {
  const formattedMetadataValues = [];
  metadata.forEach((data) => {
    if (toUpper(data.metadata_name) === toUpper(fieldName)) {
      data.metadataValues.forEach((value) => {
        formattedMetadataValues.push({
          label: value.metadata_value,
          value: value.id,
        });
      });
    }
  });
  return orderBy(formattedMetadataValues, ["label"], "asc");
};

export const formatSpecialization = (specializations) => {
  const specializationOptions = [];
  specializations.forEach((specialization) => {
    specializationOptions.push({
      label: `(${specialization.specialization_code}) - ${specialization.specialization_title}`,
      value: specialization.id,
    });
  });
  return specializationOptions;
};

export const formatProgrammes = (programmes) => {
  const formattedOptions = [];
  programmes.forEach((programme) => {
    formattedOptions.push({
      label: `(${toUpper(programme.programme_code)}) - ${toUpper(
        programme.programme_title
      )}`,
      value: programme.id,
    });
  });
  return formattedOptions;
};

export const formatProgrammeVersions = (programmeVersions) => {
  const formattedOptions = [];
  programmeVersions.forEach((version) => {
    formattedOptions.push({
      label: version.version_title,
      value: version.id,
    });
  });
  return formattedOptions;
};

export const removeEmptyOrNullObject = (obj) => {
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] &&
        typeof obj[k] === "object" &&
        removeEmptyOrNullObject(obj[k])) ||
      (!obj[k] && obj[k] !== undefined && delete obj[k])
  );
  return obj;
};

export const searchStringInArrayObject = (data, field, valueToSearch) => {
  const searchedValue = [];
  data.forEach((datum) => {
    const search = toUpper(datum[field]).includes(toUpper(valueToSearch));
    if (search === true) searchedValue.push(datum);
  });
  return searchedValue;
};

export const getObjectValues = (objectValues) => {
  Object.keys(objectValues).forEach((objectKey) => {
    if (
      !isEmpty(objectValues[objectKey]) &&
      typeof objectValues[objectKey] === "object"
    ) {
      if (Array.isArray(objectValues[objectKey])) {
        objectValues[objectKey] = objectValues[objectKey].map(
          ({ value }) => value
        );
      } else {
        objectValues[objectKey] = objectValues[objectKey].value;
      }
    }
  });
  return objectValues;
};

export const searchStringInArrayCounter = (data, valueToSearch) => {
  let searchedValue = 0;
  data.forEach((datum) => {
    const search = toUpper(datum).includes(toUpper(valueToSearch));
    if (search === true) searchedValue += 1;
  });
  return searchedValue;
};

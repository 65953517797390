import { Card } from "antd";
import { map, sumBy } from "lodash";
import moment from "moment-timezone";
import PropTypes, { object } from "prop-types";
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { InstitutionHeader } from "../../components/common";

export class PrintComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { generatedReference } = this.props;

    return (
      <>
        <div className="text-center font-weight-weight py-3">
          <InstitutionHeader />

          <div className="text-muted h6 text-sm">
            Date Generated:{" "}
            {moment
              .tz(generatedReference?.created_at, "Africa/Kampala")
              .format("LLLL")}
            {" (EAT)"}
          </div>
        </div>

        <Card title=" INVOICE DETAILS">
          <Table striped hover size="sm" bordered className="m-0">
            <tbody>
              <tr>
                <td>INVOICE NO.</td>
                <td> {generatedReference?.invoice_number}</td>
                <td rowSpan={8} className="bg-white border-start p-0">
                  <Card body className="border-0 text-center h-100">
                    PAYMENT REF:{" "}
                    <span className="h6 text-danger">
                      {generatedReference?.reference?.ura_prn}
                    </span>
                    <div className="my-2 text-md">
                      {generatedReference?.currency || "UGX"}{" "}
                      {parseInt(
                        generatedReference?.reference?.amount,
                        10
                      ).toLocaleString()}
                      /=
                    </div>
                  </Card>
                </td>
              </tr>
              <tr>
                <td>Full Name</td>
                <td>{generatedReference?.full_name}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{generatedReference?.email}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td> {generatedReference?.phone_number}</td>
              </tr>
              {generatedReference?.former_student_identifier && (
                <tr>
                  <td>Student Number</td>
                  <td>{generatedReference?.former_student_identifier}</td>
                </tr>
              )}
              <tr>
                <td>Date of Expiry</td>
                <td>{generatedReference?.reference?.expiry_date}</td>
              </tr>
            </tbody>
          </Table>
          <div className="text-primary text-uppercase text-sm bg-light fw-bold p-2 border-top text-center">
            Invoice Items
          </div>
          <Table striped hover size="sm" bordered className="mb-0">
            <thead>
              <tr className="h6 text-sm text-primary">
                <th>S/N</th>
                <th>CODE</th>
                <th>ITEM</th>
                <th>QTY</th>
                <th>UNIT AMOUNT</th>
                <th className="text-right">TOTAL AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {map(generatedReference?.receivables, (receivable, index) => (
                <tr key={receivable.id}>
                  <td>{index + 1}</td>
                  <td>{receivable?.account_code || "----------"}</td>
                  <td>{receivable?.receivable_name || "--------"}</td>
                  <td>{receivable.quantity}</td>
                  <td>
                    {(
                      parseInt(receivable?.amount, 10) /
                      parseInt(receivable?.quantity, 10)
                    ).toLocaleString()}
                  </td>
                  <td className="text-right">
                    {parseInt(receivable?.amount, 10).toLocaleString()}
                  </td>
                </tr>
              ))}
              <tr className="text-uppercase text-primary h6 fw-bold text-md">
                <td colSpan={5}>Total Amount to Pay:</td>
                <td className="text-right">
                  {sumBy(
                    generatedReference?.receivables,
                    "amount"
                  ).toLocaleString()}
                  /=
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="text-muted text-sm text-center text-uppercase">
            Generated By: {generatedReference?.full_name}
          </div>
        </Card>
      </>
    );
  }
}

PrintComponent.defaultProps = {
  generatedReference: {},
};

PrintComponent.propTypes = {
  generatedReference: PropTypes.oneOfType([object]),
};

export default connect(null, null)(PrintComponent);

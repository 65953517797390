import React from "react";
import { useSelector } from "react-redux";
import MainLayout from "../../components/shared/MainLayout";
import Home from "../Home";
import ShowInvoice from "../Home/ShowInvoice";
import ShowStudentInvoice from "../Home/ShowStudentInvoice";
import ComingSoon from "../Pages/ComingSoon";
import Payable from "../Payable";
import PaymentReference from "../PaymentReference";

function Dashboard() {
  const { currentMenu } = useSelector((state) => state.tab);

  const renderSwitchStatement = () => {
    switch (currentMenu) {
      case "home":
        return <Home />;

      case "payable":
        return <Payable />;

      case "show-invoice":
        return <ShowInvoice />;

      case "continuingStudent":
        return <ShowStudentInvoice />;

      case "reference":
        return <PaymentReference />;

      default:
        return <ComingSoon />;
    }
  };
  return <MainLayout key={currentMenu}>{renderSwitchStatement()}</MainLayout>;
}

export default Dashboard;

import { serverActions } from "../../actions";
import initialState from "../../initialState";

const server = (state = initialState.server, actions) => {
  switch (actions.type) {
    case serverActions.SERVER_REQUEST:
      return {
        success: {},
        error: {},
      };

    case serverActions.SERVER_ERROR:
      return {
        success: {},
        error: actions.error.data,
      };

    case serverActions.SERVER_SUCCESS:
      return {
        success: actions.data,
        error: {},
      };

    default:
      return state;
  }
};

export default server;

import PropTypes from "prop-types";
import React from "react";
import { Spinner } from "react-bootstrap";

function DataSpinner({ text }) {
  return (
    <div className="align-items-center py-5">
      <div className="text-center my-auto mx-auto">
        <Spinner
          animation="border"
          size="sm"
          className="p-2 d-block mx-auto mb-2"
        />
        <div className=" text-xs d-inline text-uppercase fw-bold">{text}</div>
      </div>
    </div>
  );
}

DataSpinner.defaultProps = {
  text: "Loading Data...",
};

DataSpinner.propTypes = {
  text: PropTypes.string,
};

export default DataSpinner;

import { Button } from "antd";
import PropTypes, { object } from "prop-types";
import React from "react";

function SubmitButton({
  loading,
  text,
  loadingText,
  iconBefore,
  iconAfter,
  ...props
}) {
  return (
    <Button
      type="primary"
      disabled={loading}
      loading={loading}
      htmlType="submit"
      icon={iconBefore}
      className="text-sm text-uppercase"
      {...props}
    >
      {loading ? (
        <>{loadingText}</>
      ) : (
        <>
          {text}
          {iconAfter}
        </>
      )}
    </Button>
  );
}

SubmitButton.defaultProps = {
  loading: false,
  text: "Save",
  loadingText: "Saving...",
  iconBefore: null,
  iconAfter: null,
};

SubmitButton.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.string,
  loadingText: PropTypes.string,
  iconBefore: PropTypes.oneOfType([object]),
  iconAfter: PropTypes.oneOfType([object]),
};

export default SubmitButton;

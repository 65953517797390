import { Layout } from "antd";
import { isEmpty } from "lodash";
import PropTypes, { any } from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { institutionStructureActions } from "../../config/actions";
import { InstitutionHeader } from "../common";

const { Footer, Content } = Layout;

function MainLayout({ children }) {
  const dispatch = useDispatch();
  const { institutionStructure } = useSelector(
    (state) => state.institutionStructure
  );

  useEffect(() => {
    if (isEmpty(institutionStructure))
      dispatch(institutionStructureActions.getInstitutionStructure());
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <div className="mb-4 bg-primary py-3 text-center text-white">
        <InstitutionHeader />
      </div>
      <Content>{children}</Content>
      <Footer className="border-top bg-light text-center py-4 text-muted fw-bold mt-3 text-sm">
        &copy; {new Date().getFullYear()} - ACMIS UNIVERSAL PAYMENTS PORTAL
      </Footer>
    </Layout>
  );
}

MainLayout.defaultProps = {
  children: null,
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([any]),
};

export default MainLayout;

import { isEmpty } from "lodash";
import { universalPaymentActions } from "../../actions";
import initialState from "../../initialState";

function auth(state = initialState.universalPayment, actions) {
  switch (actions.type) {
    case universalPaymentActions.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: actions.user,
      };

    case universalPaymentActions.SET_CONTINUING_STUDENT:
      return {
        ...state,
        continuingStudent: actions.student,
      };

    case universalPaymentActions.CLEAR_STUDENT_PAYMENT_REFERENCE:
      return {
        ...state,
        generatedStudentReference: {},
      };

    case universalPaymentActions.REMOVE_CURRENT_USER:
      return {
        ...state,
        currentUser: {},
      };

    case universalPaymentActions.SEARCH_INVOICE_REQUEST:
      return {
        ...state,
        searchError: {},
        searching: true,
        searchKey: null,
      };
    case universalPaymentActions.SEARCH_INVOICE_SUCCESS: {
      const { invoiceNumber, data } = actions;
      let { searchError } = state;

      if (isEmpty(data)) {
        searchError = "Invalid Invoice number provided";
      }

      return {
        ...state,
        searchKey: invoiceNumber,
        searchResult: data,
        searchError,
        searching: false,
      };
    }
    case universalPaymentActions.SEARCH_INVOICE_ERROR:
      return {
        ...state,
        searchError: actions.error,
        searching: false,
      };

    case universalPaymentActions.GENERATE_REFERENCE_REQUEST:
      return {
        ...state,
        referenceError: {},
        selectedPayables: actions.data,
        generatingReference: true,
      };
    case universalPaymentActions.GENERATE_REFERENCE_SUCCESS:
      return {
        ...state,
        generatingReference: false,
        generatedReference: actions.data,
      };
    case universalPaymentActions.GENERATE_REFERENCE_ERROR:
      return {
        ...state,
        generatingReference: false,
        referenceError: actions.error,
      };

    case universalPaymentActions.GENERATE_SEARCH_REFERENCE_REQUEST:
      return {
        ...state,
        generateSearchError: {},
        generatingSearchReference: true,
      };
    case universalPaymentActions.GENERATE_SEARCH_REFERENCE_SUCCESS:
      return {
        ...state,
        generatingSearchReference: false,
        generatedSearchReference: actions.data,
      };
    case universalPaymentActions.GENERATE_SEARCH_REFERENCE_ERROR:
      return {
        ...state,
        generatingSearchReference: false,
        generateSearchError: actions.error,
      };

    case universalPaymentActions.GENERATE_STUDENT_REFERENCE_REQUEST:
      return {
        ...state,
        generateStudentRefError: {},
        generatingStudentReference: true,
      };
    case universalPaymentActions.GENERATE_STUDENT_REFERENCE_SUCCESS:
      return {
        ...state,
        generatingStudentReference: false,
        generatedStudentReference: actions.data,
      };
    case universalPaymentActions.GENERATE_STUDENT_REFERENCE_ERROR:
      return {
        ...state,
        generatingStudentReference: false,
        generateStudentRefError: actions.error,
      };

    case universalPaymentActions.GET_PAYABLE_ITEMS_REQUEST:
      return {
        ...state,
        payableItemError: {},
        gettingPayableItems: true,
      };
    case universalPaymentActions.GET_PAYABLE_ITEMS_SUCCESS:
      return {
        ...state,
        gettingPayableItems: false,
        payableItems: actions.data,
      };
    case universalPaymentActions.GET_PAYABLE_ITEMS_ERROR:
      return {
        ...state,
        gettingPayableItems: false,
        payableItemError: actions.error,
      };

    case universalPaymentActions.SET_PAYING_USING_VISA:
      return {
        ...state,
        showVisaModal: actions.payload,
      };

    default:
      return state;
  }
}

export default auth;

import {
  ArrowLeftOutlined,
  CreditCardOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Card, Col, Space } from "antd";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import { AlertMessage, SubmitButton } from "../../components/common";
import { tabActions, universalPaymentActions } from "../../config/actions";
import usePrevious from "../Hooks/usePrevious";
import { SingleInvoice } from "./SingleInvoice";

function ShowInvoice() {
  const dispatch = useDispatch();
  const {
    searchResult,
    generatingSearchReference,
    searchKey,
    generateSearchError,
    generatedSearchReference,
  } = useSelector((state) => state.universalPayment);
  const prevState = usePrevious({ generateSearchError });
  const [errorMessage, setErrorMessage] = useState(null);

  const componentRef = useRef();

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(generateSearchError) &&
      generateSearchError !== prevState.generateSearchError
    ) {
      setErrorMessage(
        generateSearchError?.server?.message || generateSearchError?.error
      );
    }

    if (
      !isEmpty(prevState) &&
      !isEmpty(generatedSearchReference) &&
      generatedSearchReference !== prevState.generatedSearchReference
    ) {
      toast.success(
        generatedSearchReference?.server?.message ||
          "Payment Reference Generated Successfully"
      );
    }
  }, [generateSearchError, generatedSearchReference]);

  const onClickGoBack = () => {
    dispatch(tabActions.setCurrentMenu("home"));
  };

  const generateSearchReference = () => {
    setErrorMessage(null);
    if (searchKey) {
      dispatch(universalPaymentActions.generateSearchReference(searchKey));
    }
  };

  const redirectToOnlinePayment = () => {
    if (searchResult?.reference?.ura_prn) {
      const uraRedirectUrl =
        process.env.REACT_APP_URA_ONLINE_PAYMENT_REDIRECT ||
        "http://testpmtservice.ura.go.ug/payment.do?dispatch=showHistory&searchPrnNo=";

      window.open(
        `${uraRedirectUrl}${searchResult.reference.ura_prn}`,
        "_blank"
      );
    }
  };

  const showPrintButton = useCallback(() => {
    return (
      <SubmitButton
        iconBefore={<PrinterOutlined className="me-1 " />}
        htmlType="button"
        text="PRINT INVOICE"
      />
    );
  });

  return (
    <Col md={{ span: 16, offset: 4 }}>
      <div className="text-center mb-3">
        <Space>
          <SubmitButton
            iconBefore={<ArrowLeftOutlined />}
            danger
            htmlType="button"
            onClick={onClickGoBack}
            text="BACK"
          />
          <ReactToPrint
            trigger={showPrintButton}
            content={() => componentRef.current}
          />
          {searchResult?.reference?.ura_prn ? (
            <SubmitButton
              iconBefore={<CreditCardOutlined />}
              type="default"
              htmlType="button"
              onClick={redirectToOnlinePayment}
              text="PAY ONLINE WITH VISA"
            />
          ) : (
            <SubmitButton
              onClick={generateSearchReference}
              iconBefore={<CreditCardOutlined />}
              loading={generatingSearchReference}
              loadingText="Generating..."
              text="GENERATE REFERENCE"
            />
          )}
        </Space>
      </div>

      <Card>
        {errorMessage && <AlertMessage message={errorMessage} />}

        <div ref={componentRef} className="px-4">
          <SingleInvoice selectedInvoice={searchResult} />
        </div>
      </Card>
    </Col>
  );
}

export default ShowInvoice;

import { Card, Form, Space } from "antd";
import { isEmpty, toUpper } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DoubleRightOutlined } from "@ant-design/icons";
import {
  AlertMessage,
  AntDInputText,
  SubmitButton,
} from "../../components/common";
import { tabActions, universalPaymentActions } from "../../config/actions";

function UserDetailForm() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.universalPayment);
  const { institutionStructure } = useSelector(
    (state) => state.institutionStructure
  );
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(currentUser);
  }, [currentUser]);

  const handleUserDetailForm = (data) => {
    if (!isEmpty(data)) {
      dispatch(universalPaymentActions.setCurrentUser(data));
      dispatch(tabActions.setCurrentMenu("payable"));
    }
  };

  const resetForm = () => {
    dispatch(universalPaymentActions.setCurrentUser({}));
    form.resetFields([
      "full_name",
      "email",
      "phone_number",
      "former_student_identifier",
    ]);
  };

  return (
    <div className="d-block">
      <div className="my-3">
        This form is ONLY intended for{" "}
        <strong>
          <span className="text-danger mx-1">
            {toUpper(institutionStructure?.institution_name) ===
            "MAKERERE UNIVERSITY"
              ? "BBLT COVAB STUDENTS, NON-STUDENTS and FORMER STUDENTS"
              : "NON-STUDENTS and FORMER STUDENTS"}
          </span>
          of
          <span className="mx-1">
            {institutionStructure?.institution_name ||
              process.env.REACT_APP_NAME}
          </span>
          .
        </strong>
      </div>

      <AlertMessage
        type="warning"
        showIcon
        message="Continuing Students should use their Student Portal to generate Payment Reference Numbers"
        className="text-sm text-uppercase fw-bold border mb-4"
      />

      <Form
        form={form}
        onFinish={handleUserDetailForm}
        key={isEmpty(currentUser)}
        layout="horizontal"
        labelCol={{
          span: 7,
        }}
      >
        <Card bordered>
          <AntDInputText
            name="full_name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: "Full Name is Required",
              },
            ]}
          />
          <AntDInputText
            name="email"
            label="E-mail address"
            rules={[
              {
                required: true,
                message: "Email is Required",
              },
            ]}
            type="email"
          />
          <AntDInputText
            name="phone_number"
            label="Telephone No."
            type="tel"
            itemAttributes={{
              extra: "Please include country code. E.g 256",
            }}
            rules={[
              {
                required: true,
                message: "Telephone Number is Required",
              },
            ]}
          />

          <AntDInputText
            name="former_student_identifier"
            label="Student or Reg. NO."
            type="text"
            itemAttributes={{
              extra: "Only for former Students!",
            }}
          />
          <div className="text-end">
            <Space>
              {!isEmpty(currentUser) && (
                <SubmitButton
                  htmlType="reset"
                  danger
                  text="CLEAR FORM"
                  onClick={resetForm}
                />
              )}
              <SubmitButton
                text="Continue"
                iconAfter={<DoubleRightOutlined />}
              />
            </Space>
          </div>
        </Card>
      </Form>
    </div>
  );
}

export default UserDetailForm;

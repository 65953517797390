import {
  ArrowLeftOutlined,
  CodeOutlined,
  CreditCardOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { Button, Card, Col, Space } from "antd";
import { AlertMessage, SubmitButton } from "../../components/common";
import { tabActions, universalPaymentActions } from "../../config/actions";
import usePrevious from "../Hooks/usePrevious";
import { StudentInvoice } from "./StudentInvoice";

function ShowStudentInvoice() {
  const dispatch = useDispatch();
  const {
    continuingStudent,
    generatingStudentReference,
    generateStudentRefError,
    generatedStudentReference,
  } = useSelector((state) => state.universalPayment);
  const prevState = usePrevious({ generateStudentRefError });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [studentInvoice, setStudentInvoice] = useState(continuingStudent);

  const componentRef = useRef();

  useEffect(() => {
    return () => {
      if (!isEmpty(generatedStudentReference?.reference)) {
        dispatch(universalPaymentActions.setContinuingStudent({}));
        dispatch(universalPaymentActions.clearStudentPaymentReference({}));
      }
    };
  }, [generatedStudentReference]);

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(generateStudentRefError) &&
      generateStudentRefError !== prevState.generateStudentRefError
    ) {
      setErrorMessage(
        generateStudentRefError?.server?.message ||
          generateStudentRefError?.error
      );
    }

    if (
      !isEmpty(prevState) &&
      !isEmpty(generatedStudentReference) &&
      generatedStudentReference !== prevState.generatedStudentReference
    ) {
      setStudentInvoice(generatedStudentReference);
      setSuccessMessage("Payment Reference Generated Successfully");
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    }
  }, [generateStudentRefError, generatedStudentReference]);

  const onClickGoBack = () => {
    dispatch(tabActions.setCurrentMenu("home"));
  };

  const generateSearchReference = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    if (continuingStudent) {
      dispatch(
        universalPaymentActions.generateStudentReference(continuingStudent)
      );
    }
  };

  const redirectToOnlinePayment = () => {
    if (continuingStudent?.reference?.ura_prn) {
      const uraRedirectUrl =
        process.env.REACT_APP_URA_ONLINE_PAYMENT_REDIRECT ||
        "http://testpmtservice.ura.go.ug/payment.do?dispatch=showHistory&searchPrnNo=";

      window.open(
        `${uraRedirectUrl}${continuingStudent.reference.ura_prn}`,
        "_blank"
      );
    }
  };

  const showPrintButton = useCallback(() => {
    return (
      <Button
        type="primary"
        size="sm"
        className="fw-bold text-sm text-uppercase text-white"
        icon={<PrinterOutlined />}
      >
        Print Invoice
      </Button>
    );
  });

  return (
    <Col>
      <Card
        title={
          <Space>
            <Button
              variant="danger"
              size="sm"
              onClick={onClickGoBack}
              icon={<ArrowLeftOutlined />}
            >
              BACK
            </Button>

            <ReactToPrint
              trigger={showPrintButton}
              content={() => componentRef.current}
            />
            {studentInvoice?.reference?.ura_prn ? (
              <Button
                variant="outline-info"
                className="ms-2 fw-bold"
                size="sm"
                onClick={redirectToOnlinePayment}
              >
                <CreditCardOutlined />
                PAY ONLINE WITH VISA
              </Button>
            ) : (
              <SubmitButton
                variant="outline-info"
                className="ms-2 fw-bold text-sm text-uppercase"
                size="sm"
                onClick={generateSearchReference}
                iconBefore={<CodeOutlined />}
                loading={generatingStudentReference}
                loadingText="Generating..."
                text="GENERATE REFERENCE"
              />
            )}
          </Space>
        }
      >
        {errorMessage && <AlertMessage message={errorMessage} />}
        {successMessage && (
          <AlertMessage type="success" message={successMessage} />
        )}
        <Card.Body ref={componentRef} className="px-4">
          <StudentInvoice studentInvoice={studentInvoice} />
        </Card.Body>
      </Card>
    </Col>
  );
}

export default ShowStudentInvoice;

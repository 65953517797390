import { takeLatest, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { tabActions, universalPaymentActions } from "../../actions";

function* searchInvoice(actions) {
  try {
    const { invoiceNumber } = actions;
    const response = yield axios({
      url: `/universal-payments/portal/find-invoice`,
      method: "GET",
      params: { invoiceNumber },
    });
    yield put({
      type: universalPaymentActions.SEARCH_INVOICE_SUCCESS,
      data: response.data,
      invoiceNumber,
    });
  } catch (error) {
    yield put({
      type: universalPaymentActions.SEARCH_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* generateReference(actions) {
  try {
    const response = yield axios({
      url: "/universal-payments/portal/generate-reference",
      method: "POST",
      data: actions.data,
    });
    yield put({
      type: universalPaymentActions.GENERATE_REFERENCE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: tabActions.SET_CURRENT_MENU,
      currentMenu: "reference",
    });
  } catch (error) {
    yield put({
      type: universalPaymentActions.GENERATE_REFERENCE_ERROR,
      error: error.data,
    });
  }
}

function* generateSearchReference(actions) {
  try {
    const { invoiceNumber } = actions;
    const response = yield axios({
      url: `/universal-payments/portal/generate-reference/invoice`,
      method: "POST",
      params: { invoiceNumber },
    });
    yield put({
      type: universalPaymentActions.GENERATE_SEARCH_REFERENCE_SUCCESS,
      data: response,
    });
    yield put({
      type: universalPaymentActions.SEARCH_INVOICE_SUCCESS,
      data: response.data,
      invoiceNumber,
    });
  } catch (error) {
    yield put({
      type: universalPaymentActions.GENERATE_SEARCH_REFERENCE_ERROR,
      error: error.data,
    });
  }
}

function* generateStudentReference(actions) {
  try {
    const response = yield axios({
      url: "/universal-payments/portal/generate-student-payment-reference",
      method: "POST",
      data: actions.payload,
    });
    yield put({
      type: universalPaymentActions.GENERATE_STUDENT_REFERENCE_SUCCESS,
      data: response.data,
      payload: actions.payload,
    });
  } catch (error) {
    yield put({
      type: universalPaymentActions.GENERATE_STUDENT_REFERENCE_ERROR,
      error: error.data,
    });
  }
}

function* getPayableItems() {
  try {
    const response = yield axios({
      url: "/universal-payments/portal/receivables",
      method: "GET",
    });
    yield put({
      type: universalPaymentActions.GET_PAYABLE_ITEMS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: universalPaymentActions.GET_PAYABLE_ITEMS_ERROR,
      error: error.data,
    });
  }
}

function* watchSearchInvoice() {
  yield takeLatest(
    universalPaymentActions.SEARCH_INVOICE_REQUEST,
    searchInvoice
  );
}

function* watchGenerateReference() {
  yield takeLatest(
    universalPaymentActions.GENERATE_REFERENCE_REQUEST,
    generateReference
  );
}

function* watchGenerateSearchReference() {
  yield takeLatest(
    universalPaymentActions.GENERATE_SEARCH_REFERENCE_REQUEST,
    generateSearchReference
  );
}

function* watchGenerateStudentReference() {
  yield takeLatest(
    universalPaymentActions.GENERATE_STUDENT_REFERENCE_REQUEST,
    generateStudentReference
  );
}

function* watchGetPayableItems() {
  yield takeEvery(
    universalPaymentActions.GET_PAYABLE_ITEMS_REQUEST,
    getPayableItems
  );
}

const forkFunctions = [
  fork(watchSearchInvoice),
  fork(watchGenerateReference),
  fork(watchGenerateSearchReference),
  fork(watchGenerateStudentReference),
  fork(watchGetPayableItems),
];

export default forkFunctions;

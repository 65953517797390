const universalPayment = {
  SEARCH_INVOICE_SUCCESS: "SEARCH_INVOICE_SUCCESS",
  SEARCH_INVOICE_REQUEST: "SEARCH_INVOICE_REQUEST",
  SEARCH_INVOICE_ERROR: "SEARCH_INVOICE_ERROR",

  GENERATE_REFERENCE_SUCCESS: "GENERATE_REFERENCE_SUCCESS",
  GENERATE_REFERENCE_REQUEST: "GENERATE_REFERENCE_REQUEST",
  GENERATE_REFERENCE_ERROR: "GENERATE_REFERENCE_ERROR",

  GENERATE_SEARCH_REFERENCE_SUCCESS: "GENERATE_SEARCH_REFERENCE_SUCCESS",
  GENERATE_SEARCH_REFERENCE_REQUEST: "GENERATE_SEARCH_REFERENCE_REQUEST",
  GENERATE_SEARCH_REFERENCE_ERROR: "GENERATE_SEARCH_REFERENCE_ERROR",

  GENERATE_STUDENT_REFERENCE_SUCCESS: "GENERATE_STUDENT_REFERENCE_SUCCESS",
  GENERATE_STUDENT_REFERENCE_REQUEST: "GENERATE_STUDENT_REFERENCE_REQUEST",
  GENERATE_STUDENT_REFERENCE_ERROR: "GENERATE_STUDENT_REFERENCE_ERROR",

  GET_PAYABLE_ITEMS_SUCCESS: "GET_PAYABLE_ITEMS_SUCCESS",
  GET_PAYABLE_ITEMS_REQUEST: "GET_PAYABLE_ITEMS_REQUEST",
  GET_PAYABLE_ITEMS_ERROR: "GET_PAYABLE_ITEMS_ERROR",

  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_CONTINUING_STUDENT: "SET_CONTINUING_STUDENT",
  CLEAR_STUDENT_PAYMENT_REFERENCE: "CLEAR_STUDENT_PAYMENT_REFERENCE",
  REMOVE_CURRENT_USER: "REMOVE_CURRENT_USER",

  SET_PAYING_USING_VISA: "SET_PAYING_USING_VISA",

  searchInvoice: (invoiceNumber) => ({
    type: universalPayment.SEARCH_INVOICE_REQUEST,
    invoiceNumber,
  }),

  generateReference: (data) => ({
    type: universalPayment.GENERATE_REFERENCE_REQUEST,
    data,
  }),

  generateSearchReference: (invoiceNumber) => ({
    type: universalPayment.GENERATE_SEARCH_REFERENCE_REQUEST,
    invoiceNumber,
  }),

  generateStudentReference: (payload) => ({
    type: universalPayment.GENERATE_STUDENT_REFERENCE_REQUEST,
    payload,
  }),

  getPayableItems: () => ({
    type: universalPayment.GET_PAYABLE_ITEMS_REQUEST,
  }),

  setCurrentUser: (user) => ({
    type: universalPayment.SET_CURRENT_USER,
    user,
  }),

  setContinuingStudent: (student) => ({
    type: universalPayment.SET_CONTINUING_STUDENT,
    student,
  }),

  clearStudentPaymentReference: () => ({
    type: universalPayment.CLEAR_STUDENT_PAYMENT_REFERENCE,
  }),

  removeCurrentUser: () => ({
    type: universalPayment.REMOVE_CURRENT_USER,
  }),

  showVisaModal: (payload = false) => ({
    type: universalPayment.SET_PAYING_USING_VISA,
    payload,
  }),
};

export default universalPayment;

const universalPayment = {
  universalPayment: {
    currentUser: {},
    continuingStudent: {},

    searching: false,
    searchResults: [],
    searchError: {},
    searchKey: null,

    generatingReference: false,
    generatedReference: {},
    referenceError: {},

    generatingSearchReference: false,
    generatedSearchReference: {},
    generateSearchError: {},

    generatingStudentReference: false,
    generatedStudentReference: {},
    generateStudentRefError: {},

    gettingPayableItems: false,
    payableItems: [],
    payableItemError: {},
    selectedPayables: [],

    showVisaModal: false,
  },
};

export default universalPayment;

import { combineReducers } from "redux";
import institutionStructure from "./app/institutionStructure";
import server from "./app/server";
import tab from "./tab";
import universalPayment from "./universalPayment";

const appReducer = combineReducers({
  institutionStructure,
  universalPayment,
  server,
  tab,
});

const rootReducer = (state, actions) => {
  return appReducer(state, actions);
};

export default rootReducer;

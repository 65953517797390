import { Alert } from "antd";
import PropTypes from "prop-types";
import React from "react";

function AlertMessage({ message, type, ...props }) {
  return <Alert message={message} type={type} {...props} />;
}

AlertMessage.defaultProps = {
  type: "error",
  message: null,
};

AlertMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
};

export default AlertMessage;

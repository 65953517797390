const institutionStructure = {
  GET_INSTITUTION_STRUCTURE_REQUEST: "GET_INSTITUTION_STRUCTURE_REQUEST",
  GET_INSTITUTION_STRUCTURE_SUCCESS: "GET_INSTITUTION_STRUCTURE_SUCCESS",
  GET_INSTITUTION_STRUCTURE_ERROR: "GET_INSTITUTION_STRUCTURE_ERROR",

  getInstitutionStructure: () => ({
    type: institutionStructure.GET_INSTITUTION_STRUCTURE_REQUEST,
  }),
};

export default institutionStructure;

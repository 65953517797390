import {
  ArrowLeftOutlined,
  CodeOutlined,
  DeleteOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Card, Col, InputNumber, Row, Space, Tag } from "antd";
import update from "immutability-helper";
import {
  filter,
  find,
  findIndex,
  isEmpty,
  map,
  sumBy,
  toInteger,
} from "lodash";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertMessage,
  DataNotFound,
  SubmitButton,
} from "../../components/common";
import CustomTable from "../../components/common/CustomTable";
import { tabActions, universalPaymentActions } from "../../config/actions";
import { removeEmptyOrNullObject } from "../../helpers/dataFormatter";
import usePrevious from "../Hooks/usePrevious";

function Payable() {
  const dispatch = useDispatch();
  const {
    generatingReference,
    gettingPayableItems,
    payableItems,
    referenceError,
    currentUser,
  } = useSelector((state) => state.universalPayment);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const prevState = usePrevious({ referenceError });

  const reloadPayableItems = () => {
    dispatch(universalPaymentActions.getPayableItems());
  };

  useEffect(() => {
    reloadPayableItems();
  }, []);

  useEffect(() => {
    if (
      !isEmpty(referenceError) &&
      !isEmpty(prevState) &&
      referenceError !== prevState.referenceError
    ) {
      setErrorMessage(referenceError?.server?.message);
    }
  }, [referenceError]);

  const updateCellQuantity = (quantity, row) => {
    const findRowIndex = findIndex(selectedRows, (tr) => tr.id === row.id);

    const contextData = {
      ...row,
      quantity,
      amount: parseInt(quantity, 10) * parseInt(row.unit_cost, 10),
    };

    let newState = [];

    if (findIndex !== -1) {
      newState = update(selectedRows, {
        [findRowIndex]: { $set: contextData },
      });
    } else newState = update(selectedRows, { $push: [contextData] });

    setSelectedRows(newState);
  };

  const handleGenerateReference = () => {
    setErrorMessage(null);
    if (!isEmpty(selectedRows)) {
      const formData = {
        ...currentUser,
        receivables: selectedRows.map((selectedRow) => {
          return {
            receivable_id: selectedRow.id,
            quantity: selectedRow.quantity,
          };
        }),
      };
      dispatch(
        universalPaymentActions.generateReference(
          removeEmptyOrNullObject(formData)
        )
      );
    }
  };

  const columns = [
    {
      title: "ITEM",
      dataIndex: "receivable_name",
      className: "text-sm",
      width: 300,
    },
    {
      title: "CODE",
      dataIndex: "account_code",
      className: "text-sm",
      width: 80,
    },
    {
      title: "UNIT COST",
      dataIndex: "unit_cost",
      // fixed: "right",
      align: "right",
      width: 100,
      render: (text) => toInteger(text).toLocaleString(),
    },
    {
      title: "QTY",
      key: "quantity",
      editable: true,
      width: 110,
      align: "right",
      fixed: "right",
      render(text, record) {
        return (
          <InputNumber
            min={1}
            max={9000000000000}
            defaultValue={1}
            onChange={(quantity) => updateCellQuantity(quantity, record)}
            key={selectedRows.find((row) => row.id === record.id)}
          />
        );
      },
    },
  ];

  const onClickGoBack = () => {
    dispatch(tabActions.setCurrentMenu("home"));
    dispatch(tabActions.setCurrentHomeTab("fill-form"));
  };

  return (
    <Col md={{ span: 18, offset: 3 }}>
      {errorMessage && <AlertMessage description={errorMessage} />}

      <div className="text-center mb-3">
        <Space>
          <SubmitButton
            htmlType="button"
            danger
            onClick={onClickGoBack}
            text="BACK"
            iconBefore={<ArrowLeftOutlined />}
          />
          <SubmitButton
            icon={<SyncOutlined />}
            loading={gettingPayableItems}
            onClick={reloadPayableItems}
            htmlType="button"
            text="RELOAD"
            loadingText="Loading..."
          />
        </Space>
      </div>

      <Row gutter={1}>
        <Col md={{ span: 14 }}>
          <Card
            title="SELECT ITEMS TO PAY FOR, QTY COLUMN IS EDITABLE"
            loading={gettingPayableItems}
            rootClassName="p-0"
          >
            <CustomTable
              data={payableItems}
              columns={columns}
              loading={gettingPayableItems}
              setSelectedRows={(rows) =>
                setSelectedRows(
                  map(rows, (row) => ({
                    ...row,
                    quantity: 1,
                    amount: row.unit_cost,
                  }))
                )
              }
              hideIndex
              selectedRows={selectedRows}
              selectableRow
              xScroll="100%"
              onRow={(record) => {
                return {
                  onClick: () => {
                    const findRow = find(
                      selectedRows,
                      (row) => row.id === record.id
                    );
                    if (!findRow)
                      setSelectedRows((state) => [
                        ...state,
                        { ...record, quantity: 1, amount: record.unit_cost },
                      ]);
                  },
                };
              }}
            />
          </Card>
        </Col>
        <Col md={{ span: 10 }}>
          <Card
            loading={gettingPayableItems}
            title="SELECTED ITEMS"
            key={selectedRows?.length}
          >
            {isEmpty(selectedRows) ? (
              <DataNotFound message="SELECT AT LEAST 1 ITEM" />
            ) : (
              <>
                <Table
                  size="sm"
                  striped
                  borderless
                  responsive
                  className="text-sm m-0"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ITEM</th>
                      <th>QTY</th>
                      <th>UNIT</th>
                      <th colSpan={2}>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(selectedRows, (row, key) => (
                      <tr key={row.id}>
                        <td>{key + 1}</td>
                        <td>
                          <small>{row.receivable_name}</small>
                        </td>
                        <td>
                          <small>{row.quantity}</small>
                        </td>
                        <td>
                          <small>
                            {toInteger(row.unit_cost).toLocaleString()}
                          </small>
                        </td>
                        <td className="text-end">
                          <small>
                            {toInteger(row.amount).toLocaleString()}
                          </small>
                        </td>
                        <td width={25}>
                          <Tag
                            color="red-inverse"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setSelectedRows((state) =>
                                filter(state, (d) => d.id !== row.id)
                              )
                            }
                          >
                            <DeleteOutlined />
                          </Tag>
                        </td>
                      </tr>
                    ))}
                    <tr className="fw-bold text-danger text-uppercase">
                      <td colSpan={2}>
                        <small>Total Amount to Pay:</small>
                      </td>
                      <td colSpan={4} className="text-end">
                        <small>
                          {`${sumBy(
                            selectedRows,
                            "amount"
                          ).toLocaleString()} UGX`}
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            )}
            <div>
              <SubmitButton
                htmlType="submit"
                onClick={handleGenerateReference}
                disabled={isEmpty(selectedRows) || generatingReference}
                loading={generatingReference}
                block
                text="Generate PRN"
                iconBefore={<CodeOutlined />}
                loadingText="Generating..."
              />
            </div>
          </Card>
        </Col>
      </Row>
    </Col>
  );
}

export default Payable;

import PropTypes, { object } from "prop-types";
import React, { Component } from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Card } from "antd";
import { InstitutionHeader } from "../../components/common";

export class StudentInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { studentInvoice } = this.props;

    return (
      <Card className="border mb-4">
        <div className="text-center font-weight-weight pt-3">
          <InstitutionHeader />
          <hr />

          <h6 className="text-sm text-primary text-uppercase fw-bold mt-2">
            CONTINUING STUDENT Payment INVOICE
          </h6>

          <div className="text-muted fs-6 text-sm">
            Date Generated: {moment(studentInvoice?.created_at).format("LLLL")}
          </div>
        </div>
        <hr className="mb-0" />
        <Table
          striped
          hover
          size="sm"
          responsive
          borderless
          className="m-0 border-0"
        >
          <tbody>
            <tr>
              <td>Full Name</td>
              <td>{studentInvoice.full_name}</td>
              <td rowSpan={5} className="bg-white p-0 border-start">
                <Card body className="border-0 text-center my-auto">
                  PAYMENT REF:{" "}
                  <span className="h6 text-danger">
                    {studentInvoice.reference?.ura_prn ||
                      "NO REFERENCE GENERATED"}
                  </span>
                  <Card.Title className="my-2 text-md">
                    <span>AMOUNT: </span>
                    {`${parseInt(studentInvoice.amount, 10).toLocaleString()} ${
                      studentInvoice?.currency?.metadata_value || "UGX"
                    }`}
                    /=
                  </Card.Title>
                  <Card.Title className="my-2 text-md">
                    <span>PAID: </span>
                    {`${parseInt(
                      studentInvoice.amount_paid || 0,
                      10
                    ).toLocaleString()} ${
                      studentInvoice?.currency?.metadata_value || ""
                    }`}
                    /=
                  </Card.Title>
                  {studentInvoice?.reference && (
                    <div className="text-danger text-sm fw-bold">
                      EXPIRY DATE: {studentInvoice?.reference?.expiry_date}
                    </div>
                  )}
                </Card>
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{studentInvoice?.email}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td> {studentInvoice?.phone_number}</td>
            </tr>
            <tr>
              <td>Student Number</td>
              <td>{studentInvoice?.student_number}</td>
            </tr>
            <tr>
              <td>Narration</td>
              <td>{studentInvoice?.description}</td>
            </tr>
          </tbody>
        </Table>
        <div className="text-center text-sm">
          Generated By:
          <span className="text-muted ms-1">
            {studentInvoice?.reference?.generated_by}
          </span>
          <span className="text-muted ms-1">
            {moment.utc(studentInvoice?.created_at).format("LLLL")}
          </span>
        </div>
      </Card>
    );
  }
}

StudentInvoice.defaultProps = {
  studentInvoice: {},
};

StudentInvoice.propTypes = {
  studentInvoice: PropTypes.oneOfType([object]),
};

export default connect(null, null)(StudentInvoice);

import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { toast } from "react-toastify";
import Dashboard from "./containers/Dashboard";
import PageNotFound from "./containers/Errors/PageNotFound";
import "./custom.scss";
import "./index.css";
import ToggleTheme from "./components/shared/ToggleTheme";
import { getThemeSettings } from "./helpers/themeSetting";

function App() {
  const settings = getThemeSettings();
  const { serverSuccess, serverError } = useSelector((state) => state.server);
  const [darkMode, setDarkMode] = useState(settings?.isDarkMode || false);

  useEffect(() => {
    if (!isEmpty(serverError) && serverError.server) {
      toast.error(serverError.server.message);
    }
    if (!isEmpty(serverSuccess) && serverSuccess.server) {
      toast.success(serverSuccess.server.message);
    }
  }, [serverError, serverSuccess]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <ToggleTheme darkMode={darkMode} setDarkMode={setDarkMode} />
    </BrowserRouter>
  );
}

export default App;

import { DoubleRightOutlined } from "@ant-design/icons";
import React from "react";

function ComingSoon() {
  return (
    <div className="d-flex h-100">
      <div className="my-auto mx-auto text-center text-info py-4">
        <DoubleRightOutlined className="d-block h1 mx-auto text-primary" />
        <div className="h3">COMING SOON...</div>
      </div>
    </div>
  );
}

export default ComingSoon;

const appInitialState = {
  server: {
    error: {},
    success: {},
  },
  institutionStructure: {
    gettingInstitutionStructure: false,
    institutionStructure: {},
    institutionStructureError: {},
  },
};

export default appInitialState;

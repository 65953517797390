import { isEmpty } from "lodash";

export const storeThemeSettings = (settings) => {
  if (localStorage) {
    const settingsJSON = JSON.stringify(settings);

    localStorage.setItem("darkModeSettings", settingsJSON);
  }
};

export const getThemeSettings = () => {
  if (localStorage) {
    const settingsJSON = localStorage.getItem("darkModeSettings");
    if (!isEmpty(settingsJSON)) {
      const settings = JSON.parse(settingsJSON);
      return settings;
    }
  }
  return {};
};

const themeSettings = getThemeSettings();

export const defaultThemeSettings = {
  auto: false,
  brightness: 100,
  contrast: 90,
  sepia: 10,
  isDarkMode: themeSettings?.isDarkMode || false,
};

export const themeOptions = [
  { label: "DARK", value: true },
  { label: "LIGHT", value: false },
];

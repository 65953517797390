import { map, sumBy } from "lodash";
import moment from "moment";
import PropTypes, { object } from "prop-types";
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Card } from "antd";
import { InstitutionHeader } from "../../components/common";

export class SingleInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { selectedInvoice } = this.props;

    return (
      <>
        <div className="text-center py-3">
          <InstitutionHeader />
          <h6 className="text-sm font600 mt-2">
            NONE/FORMER STUDENT PAYMENT INVOICE
          </h6>

          <div className="text-muted h6 text-sm">
            Date Generated: {moment(selectedInvoice?.created_at).format("LLLL")}
          </div>
        </div>

        <Card title="INVOICE DETAILS" className="mb-4">
          <Table striped hover size="sm" bordered className="m-0">
            <tbody>
              <tr>
                <td>INVOICE NO.</td>
                <td> {selectedInvoice?.invoice_number}</td>
                <td rowSpan={8} className="bg-white border-left">
                  <div className="border-0 text-center my-auto">
                    PAYMENT REF:{" "}
                    <span className="h6 text-danger">
                      {selectedInvoice.reference?.ura_prn ||
                        "NO REFERENCE GENERATED"}
                    </span>
                    <div className="my-2 text-md">
                      <span>AMOUNT: </span>
                      {`${parseInt(
                        selectedInvoice.invoice_amount,
                        10
                      ).toLocaleString()} ${selectedInvoice?.currency}`}
                      /=
                    </div>
                    <div className="my-2 text-md">
                      <span>PAID: </span>
                      {`${parseInt(
                        selectedInvoice.amount_paid || 0,
                        10
                      ).toLocaleString()} ${selectedInvoice?.currency}`}
                      /=
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Full Name</td>
                <td>{selectedInvoice.full_name}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{selectedInvoice?.email}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td> {selectedInvoice?.phone_number}</td>
              </tr>
              {selectedInvoice?.former_student_identifier && (
                <tr>
                  <td>Student Number</td>
                  <td>{selectedInvoice?.former_student_identifier}</td>
                </tr>
              )}
              <tr>
                <td>Date of Expiry</td>
                <td>{selectedInvoice?.reference?.expiry_date}</td>
              </tr>
            </tbody>
          </Table>
          <div className="text-primary border text-uppercase text-sm bg-light fw-bold p-2 border-top text-center">
            Invoice Items
          </div>
          <Table striped hover size="sm" bordered className="mb-0">
            <thead>
              <tr className="h6 text-sm text-primary">
                <th>S/N</th>
                <th>CODE</th>
                <th>ITEM</th>
                <th>QTY</th>
                <th>UNIT AMOUNT</th>
                <th className="text-right">TOTAL AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              {map(selectedInvoice.receivables, (receivable, index) => (
                <tr key={receivable.id}>
                  <td>{index + 1}</td>
                  <td>{receivable?.account_code || "----------"}</td>
                  <td>{receivable?.receivable_name || "--------"}</td>
                  <td>{receivable.quantity}</td>
                  <td>
                    {(
                      parseInt(receivable.amount, 10) /
                      parseInt(receivable.quantity, 10)
                    ).toLocaleString()}
                  </td>
                  <td className="text-right">
                    {parseInt(receivable.amount, 10).toLocaleString()}
                  </td>
                </tr>
              ))}
              <tr className="text-uppercase text-primary h6 text-md">
                <td colSpan={5}>Total Amount to Pay:</td>
                <td className="text-right">
                  {sumBy(
                    selectedInvoice.receivables,
                    "amount"
                  ).toLocaleString()}
                  /=
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="text-center text-sm">
            Generated By:
            <span className="text-muted ms-1">
              {`${selectedInvoice?.created_by}`}
            </span>
            <span className="text-muted ms-1">
              {moment.utc(selectedInvoice?.created_at).format("LLLL")}
            </span>
          </div>
        </Card>
      </>
    );
  }
}

SingleInvoice.defaultProps = {
  selectedInvoice: {},
};

SingleInvoice.propTypes = {
  selectedInvoice: PropTypes.oneOfType([object]),
};

export default connect(null, null)(SingleInvoice);

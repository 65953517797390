import { Image, Spin } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import DefaultLogo from "../../assets/img/default.png";

function InstitutionHeader({ setSelectedMenu }) {
  const { institutionStructure, gettingInstitutionStructure } = useSelector(
    (state) => state.institutionStructure
  );

  return (
    <div>
      {gettingInstitutionStructure ? (
        <Spin tip="Please Wait.." size="large" className="text-white" />
      ) : (
        <>
          <Image
            src={`${process.env.REACT_APP_INSTITUTION_LOGO_PATH}/${institutionStructure?.institution_logo}`}
            alt="Logo"
            className="text-center mx-auto mb-2"
            onClick={setSelectedMenu}
            fallback={DefaultLogo}
            preview={false}
            draggable={false}
            height={100}
          />
          <div className="text-xs fw-bold text-uppercase">
            <div className="fs-4 my-3">
              {institutionStructure?.institution_name || ""}
            </div>
            <div className="mt-3">{process.env.REACT_APP_NAME}</div>
          </div>
        </>
      )}
    </div>
  );
}

InstitutionHeader.defaultProps = {
  setSelectedMenu: null,
};

InstitutionHeader.propTypes = {
  setSelectedMenu: PropTypes.func,
};

export default React.memo(InstitutionHeader);

import { SearchOutlined } from "@ant-design/icons";
import { Card, Col, Form, Row } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertMessage,
  AntDInputText,
  SubmitButton,
} from "../../components/common";
import { universalPaymentActions } from "../../config/actions";
import usePrevious from "../Hooks/usePrevious";

function SearchInvoice() {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const { searching, searchError } = useSelector(
    (state) => state.universalPayment
  );
  const prevState = usePrevious({ searchError });

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(searchError) &&
      searchError !== prevState.searchError
    ) {
      setErrorMessage(
        searchError?.error?.message ||
          searchError?.server?.message ||
          searchError?.error ||
          searchError
      );
    }
  }, [searchError]);

  const handleSearchInvoice = (data) => {
    setErrorMessage(null);
    if (!isEmpty(data?.searchKey)) {
      dispatch(universalPaymentActions.searchInvoice(data.searchKey));
    }
  };

  return (
    <div className="mt-3">
      <div className="text-sm fw-bold text-uppercase mb-2 text-success">
        Already Have an Invoice? Let&lsquo;s Search It!
      </div>
      {errorMessage && (
        <AlertMessage showIcon className="mb-2" message={errorMessage} />
      )}
      <Form layout="horizontal" name="search" onFinish={handleSearchInvoice}>
        <Card className="bg-light rounded-sm w-100">
          <Row gutter={1}>
            <Col md={{ span: 16 }}>
              <AntDInputText
                label="ENTER INVOICE NUMBER"
                name="searchKey"
                inputAttributes={{
                  placeholder: "E.g INV1234567890",
                }}
                rules={[
                  {
                    required: "Invoice Number is Required",
                  },
                ]}
                type="search"
              />
            </Col>
            <Col md={{ span: 8 }}>
              <Form.Item>
                <SubmitButton
                  iconBefore={<SearchOutlined />}
                  loading={searching}
                  loadingText="Searching..."
                  text="Search Invoice"
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
}

export default SearchInvoice;

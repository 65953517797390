import axios from "axios";
import { get, isEmpty } from "lodash";
import { serverActions } from "../actions";

const getErrorMessage = (response) => {
  const error = get(response, "data", {
    server: { message: "Something went Wrong!" },
  });
  return error.server.message;
};

const httpService = {
  setupInterceptors: (store) => {
    const { dispatch } = store;

    axios.interceptors.request.use(
      async (config) => {
        const localConfig = config;
        if (!localConfig.thirdParty) {
          if (!localConfig.headers["Content-type"]) {
            localConfig.headers["Content-type"] = "application/json";
          }
          localConfig.headers.Accept = "application/json";
          localConfig.timeout = 60000;
          localConfig.baseURL = process.env.REACT_APP_API_BASE_URL;
        }
        dispatch(serverActions.serverRequest());
        return localConfig;
      },
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (response) => {
        const data = get(response, "data", {});
        const config = get(response, "config", {});
        if (config && config.method !== "get" && !config.hideMessage) {
          if (data) {
            dispatch(serverActions.serverSuccess(data));
          }
        }
        // Return entire response if response type blob
        if (config && config.responseType === "blob") return response;
        return data;
      },
      (error) => {
        const codeError = get(error, "code", "");
        let errorResponse = get(error, "response", {});

        if (!isEmpty(errorResponse)) {
          if (errorResponse.status === 404) {
            errorResponse = {
              data: {
                server: {
                  message:
                    errorResponse.data?.message || errorResponse.statusText,
                },
              },
            };
          } else if (!error.config.hideErrorMessage) {
            errorResponse = {
              data: { server: { message: getErrorMessage(errorResponse) } },
            };
          }
        } else if (codeError === "ECONNABORTED") {
          errorResponse = {
            data: {
              server: {
                message: "Sorry... Connection time out, Please try again!",
              },
            },
          };
        } else {
          errorResponse = {
            data: {
              server: {
                message:
                  "Connection Failed, Please check your Internet connection and try again.",
              },
            },
          };
        }
        dispatch(serverActions.serverError(errorResponse));
        return Promise.reject(errorResponse);
      }
    );
  },
};

export default httpService;

import { tabActions } from "../actions";
import initialState from "../initialState";

const tab = (state = initialState.tab, actions) => {
  switch (actions.type) {
    case tabActions.SET_CURRENT_HOME_TAB:
      return {
        ...state,
        currentHomeTab: actions.activeTab,
      };

    case tabActions.SET_CURRENT_MENU:
      return {
        ...state,
        currentMenu: actions.currentMenu,
      };

    default:
      return state;
  }
};

export default tab;
